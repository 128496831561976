// react
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// third-party
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import getInfoData from '../../api/info';
// import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';
import { toastError } from '../toast/toastComponent';
import BlockLoader from '../blocks/BlockLoader';

function FooterLinkPage() {
    // const state = useSelector((state) => state);
    // const lanData = state.languageData;
    const { formatMessage } = useIntl();
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [apiLoaded, setApiLoaded] = useState(false);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaImage, setMetaImage] = useState('');
    const [type, setType] = useState('');
    const [slug, setSlug] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        getInfoData((success) => {
            setIsLoading(false);
            if (success.data) {
                setApiLoaded(true);
                const filteredData = success.data.filter((item) => {
                    switch (window.location.pathname) {
                    case '/pages/about-us':
                        return item.type === 'about_us';
                    case '/pages/terms':
                        return item.type === 'terms_conditions';
                    case '/pages/shipment-policy':
                        return item.type === 'shipping_policy';
                    case '/pages/refund-policy':
                        return item.type === 'refund_policy';
                    case '/pages/privacy-policy':
                        return item.type === 'privacy_policy';
                    default:
                        return 'Else';
                    }
                });
                setContent(filteredData[0].content);
                setMetaTitle(filteredData[0]?.metaTitle);
                setMetaDescription(filteredData[0].meta_description);
                setMetaImage(filteredData[0].meta_image);
                setType(filteredData[0]?.type);
                setSlug(filteredData[0]?.slu);
                setTitle(filteredData[0]?.title);
            }
        }, (fail) => {
            setIsLoading(false);
            setApiLoaded(false);
            toastError(fail);
            // console.log(type, 5555);
        });
    }, [window.location.pathname]);
    if (isLoading) return <BlockLoader />;

    return (
        <div className="block about-us">
            <Helmet>
                <title>{`${type} — ${theme.name}`}</title>
                <meta name="description" content={`${metaDescription}`} />
                <meta name="twitter:site" content="@publisher_handle" />
                <meta name="twitter:title" content={`${metaTitle}`} />
                <meta name="twitter:description" content={`${metaDescription}`} />
                <meta name="twitter:creator" content="@author_handle" />
                <meta name="twitter:image" content={`${metaImage}`} />
                <meta property="og:title" content={`${metaTitle}`} />
                <meta property="og:type" content={`${type}`} />
                <meta property="og:url" content={`https://koolen.com.sa/pages/${slug}`} />
                <meta property="og:image" content={`${metaImage}`} />
                <meta property="og:description" content={`${metaDescription}`} />
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/about-us2.jpg")' }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            {apiLoaded ? (
                                <div className="about-us__container">
                                    <h1 className="about-us__title" style={{ textTransform: 'capitalize' }}>{title}</h1>
                                    <div className="about-us__text" dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            ) : (
                                <div className="about-us__container">
                                    <h1 className="about-us__title">
                                        {title}
                                    </h1>
                                    <h5 className="text-center">{formatMessage({ id: 'noContent' })}</h5>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterLinkPage;

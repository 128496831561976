// react
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
// import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';
import shopApi from '../../api/shop';
// import { url } from '../../services/utils';

// blocks
import BlockLoader from '../blocks/BlockLoader';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
import categories from '../../data/shopWidgetCategories';
// import theme from '../../data/theme';
// api
import { getProductDetails, getRelatedProducts } from '../../api/products';
import prodcutsSchema, { singleProductSchema } from '../../helpers/productSchema';
import { toastError } from '../toast/toastComponent';
import BlockOffersCarousel from '../blocks/BlockOffersCarousel';

function ShopPageProduct(props) {
    const state = useSelector((state) => state);
    const lanData = state.languageData;
    const { productSlug, layout, sidebarPosition } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);
    const [productTitle, setProductTitle] = useState('');
    const [productDesc, setProductDesc] = useState('');
    const [productImage, setProductImage] = useState('');
    const [productSlug2, setProductSlug] = useState('');
    const [productCanonical, setProductCanonical] = useState('');
    const [productAltImage, setProductAltImage] = useState('');
    const [productTags, setProductTags] = useState('');
    const intl = useIntl();

    useEffect(() => {
        setIsLoading(true);
        getProductDetails(
            productSlug,
            (success) => {
                setIsLoading(false);
                if (success.success) {
                    const product = singleProductSchema(success.data);
                    setProductTitle(success.data.metaTitle);
                    setProductDesc(success.data.meta_description);
                    setProductImage(success.data.photos[0]);
                    setProductSlug(success.data.slug);
                    setProductCanonical(success.data.canonical);
                    setProductAltImage(success.data.alt_image);
                    setProductTags(success.data.tags);
                    setProduct(product[0]);
                } else toastError(success);
            },
            (fail) => {
                setIsLoading(false);
                toastError(fail);
            },
        );
    }, [productSlug]);
    // Load related products.
    useEffect(() => {
        if (product) {
            getRelatedProducts(
                product.id,
                (success) => {
                    if (success.success) {
                        const products = prodcutsSchema(success.data);
                        setRelatedProducts(products);
                    } else {
                        toastError(success);
                    }
                },
                (fail) => {
                    toastError(fail);
                },
            );
        }
    }, [productSlug, setRelatedProducts, product]);

    // Load latest products.
    useEffect(() => {
        let canceled = false;

        if (layout !== 'sidebar') {
            setLatestProducts([]);
        } else {
            shopApi.getLatestProducts({ limit: 5 }).then((result) => {
                if (canceled) {
                    return;
                }

                setLatestProducts(result);
            });
        }

        return () => {
            canceled = true;
        };
    }, [layout]);

    if (isLoading) {
        return <BlockLoader />;
    }

    let content;

    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts
                            title={intl.formatMessage({ id: 'latestProducts' })}
                            products={latestProducts}
                        />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            {product && <Product product={product} layout={layout} productAltImage={productAltImage} />}
                            <ProductTabs withSidebar />
                        </div>

                        {relatedProducts.length > 0 && (
                            <BlockProductsCarousel
                                title="Related Products"
                                layout="grid-4-sm"
                                products={relatedProducts}
                                withSidebar
                            />
                        )}
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        {product && <Product product={product} layout={layout} productAltImage={productAltImage} />}
                        <ProductTabs product={product} />
                    </div>
                </div>

                {relatedProducts.length > 0 && (
                    <BlockProductsCarousel title={lanData?.related_products} layout="grid-5" products={relatedProducts} />
                )}
            </React.Fragment>
        );
    }
    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts
                            title={intl.formatMessage({ id: 'latestProducts' })}
                            products={latestProducts}
                        />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            {product && <Product product={product} layout={layout} productAltImage={productAltImage} />}
                            <ProductTabs withSidebar />
                        </div>

                        {relatedProducts.length > 0 && (
                            <BlockOffersCarousel
                                title="Related Products"
                                layout="grid-4-sm"
                                products={relatedProducts}
                                withSidebar
                            />
                        )}
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        {product && <Product product={product} layout={layout} productAltImage={productAltImage} />}
                        <ProductTabs product={product} />
                    </div>
                </div>

                {relatedProducts.length > 0 && (
                    <BlockOffersCarousel title={lanData?.related_products} layout="grid-5" products={relatedProducts} />
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${productTitle}`}</title>
                <meta name="robots" content="index,follow" />
                <meta name="description" content={`${productDesc}`} />
                <link rel="canonical" href={`${productCanonical}`} />
                <meta name="keywords" content={`${productTags}`} />
                <meta name="twitter:card" content="product" />
                <meta name="twitter:site" content="@publisher_handle" />
                <meta name="twitter:title" content={`${productTitle}`} />
                <meta name="twitter:description" content={`${productDesc}`} />
                <meta name="twitter:creator" content="@author_handle" />
                <meta name="twitter:image" content={`${productImage}`} />
                <meta property="og:title" content={`${productTitle}`} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://koolen.com.sa/products/${productSlug2}`} />
                <meta property="og:image" content={`${productImage}`} />
                <meta property="og:description" content={`${productDesc}`} />
                <meta property="og:site_name" content="" />
                <meta property="fb:app_id" content="" />
            </Helmet>
            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

export default ShopPageProduct;

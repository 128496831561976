import React from 'react';
import { useSelector } from 'react-redux';
// import { useIntl } from 'react-intl';
// import { FormattedMessage } from 'react-intl';
import BlockLoader from './BlockLoader';
// import './ChooseAddress.css';

const ChooseAddress = ({
    address,
    isLoading,
    handleAddressClick,
    handleClick,
    checked,
}) => {
    const state = useSelector((state) => state);
    const locale = state?.locale;
    // const { formatMessage } = useIntl();
    if (isLoading && !address) return <BlockLoader />;
    const importPromise = locale === 'ar' ? import('./ChooseAddressAr.css') : import('./ChooseAddress.css');
    if (address && importPromise) {
        return (
            <div
                className="custom-address mb-2"
                role="button"
                onKeyDown={handleClick}
                tabIndex={0}
                onClick={handleClick}
            >
                <input
                    type="radio"
                    name="radio-group"
                    id={`radio-input-label-${address.id}`}
                    onClick={() => handleAddressClick(address.id)}
                    defaultChecked={checked}
                />
                <label htmlFor={`radio-input-label-${address.id}`} className="card custom-address__card">
                    <div className="address-card">
                        <div className="address-card__body d-flex justify-content-between align-items-center">
                            <div className="info">
                                <div className="one d-flex">
                                    <div className="address-card__name address">{`${address.address},`}</div>
                                    <div className="address-card__row-content">{address.postal_code}</div>
                                </div>
                                <div className="two d-flex">
                                    <div className="address-card__row-content city">{`${address.city},`}</div>
                                    <div className="address-card__row-content">{address.country}</div>
                                </div>
                                <div className="address-card__row-content">{address.phone}</div>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
        );
    }
    return null;
};

export default ChooseAddress;

// react
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import { FaXTwitter } from 'react-icons/fa6';
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';
import getFooterData from '../../api/footer';
import { toastError } from '../toast/toastComponent';
import BlockLoader from '../blocks/BlockLoader';

function SitePageContactUs() {
    const state = useSelector((state) => state);
    const lanData = state.languageData;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const { formatMessage } = useIntl();

    function fetchFooterData() {
        getFooterData((success) => {
            const { contact_info: { contact_address, contact_email }, contact_numbers: { contact_phone, customers_service_number }, social_link } = success;
            setData({
                address: contact_address,
                email: contact_email,
                phone: [contact_phone, customers_service_number],
                social_link,
            });

            setIsLoading(false);
        }, (fail) => {
            setIsLoading(false);
            toastError(fail);
        });
    }
    useEffect(() => {
        fetchFooterData();
    }, []);

    if (isLoading) return <BlockLoader />;
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>

            <BlockMap />

            <PageHeader header={lanData?.contact_us} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">{lanData?.address}</h4>

                                        <div className="contact-us__address">
                                            <p>
                                                {data.email}
                                                <br />
                                                {lanData?.email}
                                                :
                                                {data.email}
                                                <br />
                                                رقم الجوال : 8001242003
                                                <br />
                                                +خدمة العملاء للأجهزة الصغيرة 966580419139
                                                <br />
                                                +966594910660 خدمة العملاء للأجهزة الكبيرة ( مكيفات + غسالات ... الخ)
                                                <br />
                                                أوقات الاتصال من الساعة 9 صباحا حتي 5 مساءا ما عدا الجمعة والسبت
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="contact-us__header card-title mt-3">
                                    {formatMessage({ id: 'footer.followUs' })}
                                </h4>
                                <div className="social-links  social-links--shape--circle">
                                    <ul className="social-links__list">
                                        {data?.social_link['facebook-f'] && (
                                            <li className="social-links__item">
                                                <a
                                                    className="social-links__link social-links_link--type--facebook"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.facebook.com/koolen.sa"
                                                >
                                                    <i className="fab fa-facebook" />
                                                </a>
                                            </li>
                                        )}
                                        {data?.social_link?.instagram && (
                                            <li className="social-links__item">
                                                <a
                                                    className="social-links__link social-links_link--type--facebook"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.instagram.com/koolenksa/"
                                                >
                                                    <i className="fab fa-instagram" />
                                                </a>
                                            </li>
                                        )}
                                        {data?.social_link?.twitter && (
                                            <li className="social-links__item">
                                                <a
                                                    className="social-links__link social-links_link--type--twitter"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://twitter.com/KoolenKSA"
                                                >
                                                    <FaXTwitter />
                                                </a>
                                            </li>
                                        )}
                                        <li className="social-links__item">
                                            <a
                                                className="social-links__link social-links_link--type--twitter"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.snapchat.com/add/koolenksa"
                                            >
                                                <i className="fab fa-snapchat-ghost" />
                                            </a>
                                        </li>
                                        <li className="social-links__item">
                                            <a
                                                className="social-links__link social-links_link--type--twitter"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.tiktok.com/@koolen.ksa"
                                            >
                                                <i className="fab fa-tiktok" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;

export default [
    {
        title: 'SAR',
        currency: {
            code: 'SAR',
            symbol: 'SAR',
            name: 'Saudi Arabian Riyals',
        },
    },
];

// react
import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';

// third-party
import { Link } from 'react-router-dom';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
// store
import { LOGOUT } from '../../store/auth/auth.types';

function IndicatorAccount(props) {
    const state = useSelector((state) => state);
    const lanData = state.languageData;
    const {
        dispatch,
        auth: { user, token },
    } = props;
    // (token);
    const { formatMessage } = useIntl();
    function logoutUser() {
        dispatch({ type: LOGOUT });
    }

    const dropdown = token ? (
        <div className="account-menu">
            {token && (
                <Link to="/account/dashboard" className="account-menu__user">
                    <div className="account-menu__user-avatar">
                        <img src="images/avatars/profile-avatar.png" alt="avatar" />
                    </div>
                    <div className="account-menu__user-info">
                        <div className="account-menu__user-name">{user.name}</div>
                        <div className="account-menu__user-email">{user.email}</div>
                    </div>
                </Link>
            )}

            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    <Link to="/account/profile">{formatMessage({ id: 'editProfile' })}</Link>
                </li>
                <li>
                    <Link to="/account/orders">{formatMessage({ id: 'orderHistory' })}</Link>
                </li>
                <li>
                    <Link to="/account/addresses">{lanData?.addresses}</Link>
                </li>
                <li>
                    <Link to="/account/password">{lanData?.password}</Link>
                </li>
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    {/* eslint-disable */}
                    <Link to="/account/login" onClick={logoutUser} onKeyDown={logoutUser}>
                        {lanData?.logout}
                    </Link>
                </li>
            </ul>
        </div>
    ) : (
        <React.Fragment />
    );

    return (
        <React.Fragment>
            <Indicator url={!token ? '/account/login' : null} dropdown={dropdown} isAccount icon={<Person20Svg />} />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps)(IndicatorAccount);

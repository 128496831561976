import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import getAirConditions from '../../api/airConditions';
import { toastError } from '../toast/toastComponent';
import BlockLoader from '../blocks/BlockLoader';
import './SitePageAirConditions.css';

const SitePageAirConditions = () => {
    const intl = useIntl();
    const [airConditionsData, setAirConditionsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getAirConditions(
            (success) => {
                setIsLoading(false);
                setAirConditionsData(success?.data);
            },
            (fail) => {
                setIsLoading(false);
                toastError(fail);
            },
        );
    }, []);
    const items = Object.keys(airConditionsData).map((arrayOfObj) => airConditionsData[arrayOfObj].map((obj, index) => (index > 0 && obj.branch === airConditionsData[arrayOfObj][index - 1].branch ? (
        <tr>
            <td>{obj.store}</td>
            <td className="map">
                <a className="btn" target="blank" href={obj.map_link}>
                    {intl.formatMessage({ id: 'map' })}
                </a>
            </td>
            <td>
                <a href={`tel:${obj.phone}`}>{obj.phone}</a>
            </td>
        </tr>
    ) : (
        <tr>
            <th scope="row" rowSpan={airConditionsData[arrayOfObj].length}>
                {obj.branch}
            </th>
            <td>{obj.store}</td>
            <td className="map">
                <a className="btn" target="blank" href={obj.map_link}>
                    {intl.formatMessage({ id: 'map' })}
                </a>
            </td>
            <td>
                <a href={`tel:${obj.phone}`}>{obj.phone}</a>
            </td>
        </tr>
    ))));
    if (isLoading) return <BlockLoader />;
    return (
        <div className="image-container">
            <div className="mb-4 split-ac" />
            <div className="table-container " dir="ltr">
                <table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">{intl.formatMessage({ id: 'branch' })}</th>
                            <th scope="col">{intl.formatMessage({ id: 'store' })}</th>
                            <th scope="col">{intl.formatMessage({ id: 'mapLink' })}</th>
                            <th scope="col">{intl.formatMessage({ id: 'phoneNumber' })}</th>
                        </tr>
                    </thead>
                    <tbody>{items}</tbody>
                </table>
            </div>
        </div>
    );
};

export default SitePageAirConditions;

// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';

// data stubs
import currencies from '../../data/shopCurrencies';
import mobileMenuLinks from '../../data/mobileMenu';
import Dropdown from '../header/Dropdown';
import { getCurrentLanguage } from '../../api/network';
// import Menu from '../header/Menu';
// import English from './English';

function MobileMenu(props) {
    const state = useSelector((state) => state);
    const lanData = state.languageData;
    // console.log(state);
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
    } = props;
    const languages = [
        {
            title: <FormattedMessage id="arabic" />,
            locale: 'ar',
            code: 'Arabic',
            icon: 'images/languages/ar.png',
            icon_srcset: 'images/languages/ar.png',
        },
        {
            title: <FormattedMessage id="english" />,
            locale: 'en',
            code: 'EN',
            icon: 'images/languages/en.png',
            icon_srcset: 'images/languages/en.png',
        },
    ];

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };
    const language = languages.find((x) => x.locale === state.locale);
    const title = (
        <React.Fragment>
            {/* <FormattedMessage id="topbar.language" defaultMessage="Language" /> */}
            {lanData?.language}
            {': '}
            <span className="topbar__item-value">{language.title}</span>
        </React.Fragment>
    );

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">
                        <FormattedMessage id="menu" />
                    </div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
                    <div className="mobile-links__item">
                        <div className="mobile-links__item-title">
                            <Dropdown
                                title={title}
                                withIcons
                                items={languages}
                                onClick={(item) => {
                                    getCurrentLanguage(item.code);
                                    changeLocale(item.locale);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);

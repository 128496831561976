// react
import React from 'react';
import { useSelector } from 'react-redux';

// data stubs

export default function FooterContacts({ data }) {
    const state = useSelector((state) => state);
    // console.log(state);
    const lanData = state.languageData;
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">{lanData.contact_address}</h5>
            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {data?.contact_info.contact_address}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    <a href={`mailto:${data?.contact_info.contact_email}`}>{data?.contact_info.contact_email}</a>
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />

                    <a href="tel:8001242003">8001242003</a>
                </li>
                <li>
                    <i className="footer-contacts__icon fab fa-whatsapp" />

                    <a href="https://wa.me/966580419139">
                        +966580419139
                        <br />
                        خدمة العملاء للأجهزة الصغيرة
                    </a>
                </li>
                <br />
                <li>
                    <i className="footer-contacts__icon fab fa-whatsapp" />
                    <a href="https://wa.me/966594910660">
                        +966594910660
                        <br />
                        خدمة العملاء للأجهزةالكبيرة
                    </a>
                </li>
                <br />
                <li>
                    <i className="footer-contacts__icon fa fa-phone" />

                    <a href="tel:8001242003">
                        أوقات الاتصال من الساعة 9 صباحا
                        <br />
                        حتي 5 مساءا ما عدا الجمعة والسبت
                    </a>
                </li>
            </ul>
        </div>
    );
}

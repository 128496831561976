import GET_LANGUAGE_DATA from './lan.types';

const language = JSON.parse(localStorage.getItem('state'))?.locale || 'en';
export function getData(data) {
    return {
        type: GET_LANGUAGE_DATA,
        payload: data,
    };
}
export function fetchata() {
    return async (dispatch) => {
        // const res = await fetch(`https://koolen.shaha.com.sa/dashboard/public/api/v1/locale/${language}`);
        const res = await fetch(`https://koolen.com.sa/dashboard/public/api/v1/locale/${language}`);
        const lanData = await res.json();
        dispatch(getData(lanData));
    };
}

export const url = {
    home: () => '/',

    // catalog: () => '/shop/catalog',
    catalog: () => '/collections',

    // category: (category) => `/shop/catalog/${category.slug}`,
    category: (category) => `/collections/${category.slug}`,

    // product: (product) => `/shop/products/${product.slug}`,
    product: (product) => `/products/${product.slug}`,
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}

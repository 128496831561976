export default [
    {
        type: 'link',
        label: 'home',
        url: '/',
    },
    {
        type: 'link',
        label: 'topbar.shop',
        url: '/shop/catalog',
        // url: '/collections',
    },
    {
        type: 'link',
        label: 'topbar.categories',
        // url: '/categories',
        url: '/collections',
    },
    {
        type: 'link',
        label: 'footer.contactus',
        // url: '/site/contact-us',
        url: '/pages/contact-us',
    },
    {
        type: 'link',
        label: 'aboutUs',
        // url: '/site/about-us',
        url: '/pages/about-us',
    },
    {
        type: 'link',
        label: 'splitAirConditions',
        // url: '/split-air-conditions',
        // url: '/collections/split-air-conditions',
        // url: '/collections/مكيفات-سبليت',
        url: '/مكيفات-سبليت',
    },
    // {
    //     type: 'link',
    //     label: 'English',
    //     // url: '/مكيفات-سبليت',
    // },
    // {
    //     type: 'link',
    //     label: 'Arabic',
    //     // url: '/مكيفات-سبليت',
    // },
];

// react
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaXTwitter } from 'react-icons/fa6';

// application
import { subscribe } from '../../api/footer';
import { toastError, toastSuccess } from '../toast/toastComponent';
import './FooterNewsletter.css';

export default function FooterNewsletter({ data }) {
    const state = useSelector((state) => state);
    const lanData = state.languageData;
    const [email, setEmail] = useState('');

    function addEmail(e) {
        e.preventDefault();
        subscribe(email, (success) => {
            if (success.success) {
                toastSuccess(success);
            } else {
                toastError(success);
            }
        }, (fail) => { toastError(fail); });
    }

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">{lanData?.contact_us}</h5>
            <form className="footer-newsletter__form">
                <input
                    type="email"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder={lanData?.email_address}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" onClick={addEmail} className="footer-newsletter__form-button btn btn-primary">
                    {lanData?.subscribe}
                </button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">{lanData?.social_links}</div>
            <div className="social-links  social-links--shape--circle">
                <ul className="social-links__list">
                    {data?.social_link['facebook-f'] && (
                        <li className="social-links__item">
                            <a
                                className="social-links__link social-links_link--type--facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/koolen.sa"
                            >
                                <i className="fab fa-facebook" />
                            </a>
                        </li>
                    )}
                    {data?.social_link?.instagram && (
                        <li className="social-links__item">
                            <a
                                className="social-links__link social-links_link--type--facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/koolenksa/"
                            >
                                <i className="fab fa-instagram" />
                            </a>
                        </li>
                    )}
                    {data?.social_link?.twitter && (
                        <li className="social-links__item">
                            <a
                                className="social-links__link social-links_link--type--twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/KoolenKSA"
                            >
                                <FaXTwitter />
                            </a>
                        </li>
                    )}

                    <li className="social-links__item">
                        <a
                            className="social-links__link social-links_link--type--twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.snapchat.com/add/koolenksa"
                        >
                            <i className="fab fa-snapchat-ghost" />
                        </a>
                    </li>
                    <li className="social-links__item">
                        <a
                            className="social-links__link social-links_link--type--twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.tiktok.com/@koolen.ksa"
                        >
                            <i className="fab fa-tiktok" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default [
    // {
    //     key: 'cash_on_delivery',
    //     title: 'cashOnDelivery',
    //     description: 'payWithCash',
    // },
    {
        key: 'paytabs',
        title: 'paytabs',
        description: 'paypalDesc',
    },
];

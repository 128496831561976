// react
import { React, useState, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import { useIntl } from 'react-intl';
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

// apis
import { loginUser, signUpUser } from '../../api/auth';
// store
import { LOGIN } from '../../store/auth/auth.types';
import { toastError, toastSuccess } from '../toast/toastComponent';
import { getToken } from '../../api/network';
import AccountVerify from './AccountVerify';

function AccountPageLogin(props) {
    const [userEmail, setUserEmail] = useState('');
    const state = useSelector((state) => state);
    const lanData = state.languageData;
    const { dispatch } = props;
    const history = useHistory();
    const intl = useIntl();
    // console.log(props);
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(intl.formatMessage({ id: 'validation.email.format' })).required(intl.formatMessage({ id: 'validation.email.required' })),
            password: Yup.string().min(6, intl.formatMessage({ id: 'validation.password.format' })).required(intl.formatMessage({ id: 'validation.password.required' })),
        }),
        onSubmit: (values) => {
            const { email, password } = values;
            loginUser({ email, password }, (success) => {
                if (success.success) {
                    const { access_token: token, user } = success;
                    getToken(token);
                    dispatch({ type: LOGIN, payload: { token, user } });
                } else {
                    toastError(success);
                }
            }, (fail) => {
                toastError(fail);
            });
        },
    });
    const registrationFormik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            phone: '',
            password: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object({
            fullName: Yup.string().required(intl.formatMessage({ id: 'validation.fullName.required' })),
            email: Yup.string()
                .email(lanData?.please_enter_a_valid_email_address)
                .required(lanData?.enter_your_email_address),
            phone: Yup.number()
                .typeError(lanData?.phone_number_must_be_valid)
                .required(intl.formatMessage({ id: 'validation.phone.required' }))
                .positive(intl.formatMessage({ id: 'validation.phone.format' }))
                .integer(lanData?.phone_number_must_be_valid)
                .test(
                    'saudi-number',
                    intl.formatMessage({ id: 'validation.saudiPhone.format' }),
                    (value) => /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(value),
                ),
            password: Yup.string()
                .min(6, lanData?.password_must_be_minimum_6_characters)
                .required(intl.formatMessage({ id: 'validation.password.required' })),
            repeatPassword: Yup.string()
                .required(lanData?.confirm_password)
                .oneOf([Yup.ref('password'), null], lanData?.password_and_confirm_password_should_match),
        }),
        onSubmit(values) {
            const {
                fullName, phone, email, password,
            } = values;
            signUpUser(
                {
                    name: fullName,
                    email,
                    password,
                    phone,
                },
                (success) => {
                    if (success.success) {
                        toastSuccess(success);
                        setUserEmail(email);
                        // console.log(userEmail);
                        registrationFormik.resetForm();
                        const { access_token: token, user } = success;
                        getToken(token);
                        dispatch({ type: LOGIN, payload: { token, user } });
                        history.push('/account/verify', { email: success.email_value });
                    } else {
                        toastError(success);
                        registrationFormik.resetForm();
                    }
                },
                (fail) => {
                    toastError(fail);
                },
            );
        },
    });

    return (
        <Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>
            <div style={{ display: 'none' }}>
                {/* <div> */}
                <AccountVerify theEmail={userEmail} />
                {/* <AccountVerify theEmail="sth" /> */}
                {/* <AccountVerify theEmail={formik.values.email} /> */}
            </div>

            <div className="mt-2">
                <PageHeader header="My Account" />
            </div>
            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">{lanData?.login}</h3>
                                    <form className="needs-validation" onSubmit={formik.handleSubmit} noValidate>
                                        <div className="form-group needs-validation">
                                            <label htmlFor="login-email">{lanData?.email}</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                name="email"
                                                placeholder={lanData?.your_email_address}
                                                className={`form-control ${formik.errors.email && formik.touched.email && 'is-invalid'}`}
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                {...formik.getFieldProps('email')}
                                            />
                                            {
                                                formik.touched.email && formik.errors.email
                                                    ? (
                                                        <div className="invalid-feedback">
                                                            {formik.errors.email}
                                                        </div>
                                                    )
                                                    : null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">{lanData?.password}</label>
                                            <input
                                                id="login-password"
                                                type="password"
                                                name="password"
                                                className={`form-control ${formik.touched.password && formik.errors.password && ' is-invalid'}`}
                                                placeholder={lanData?.password}
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                                {...formik.getFieldProps('password')}
                                            />
                                            {
                                                formik.touched.password && formik.errors.password
                                                    ? (
                                                        <div className="invalid-feedback">
                                                            {formik.errors.password}
                                                        </div>
                                                    )
                                                    : null
                                            }
                                        </div>
                                        <small className="form-text text-muted">
                                            <Link to="/account/forget-password">{lanData?.forgot_password}</Link>
                                        </small>
                                        <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                            {lanData?.login}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">{lanData?.register}</h3>
                                    <form onSubmit={registrationFormik.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name">{lanData?.full_name}</label>
                                            <input
                                                id="name"
                                                type="text"
                                                name="fullName"
                                                className={`form-control ${registrationFormik.touched.fullName && registrationFormik.errors.fullName && 'is-invalid'}`}
                                                placeholder={lanData?.full_name}
                                                value={registrationFormik.values.fullName}
                                                onChange={registrationFormik.handleChange}
                                                {...registrationFormik.getFieldProps('fullName')}
                                            />
                                            {
                                                registrationFormik.touched.fullName && registrationFormik.errors.fullName
                                                    ? (
                                                        <div className="invalid-feedback">
                                                            {registrationFormik.errors.fullName}
                                                        </div>
                                                    )
                                                    : null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone">{lanData?.phone_number}</label>
                                            <input
                                                id="phone"
                                                type="text"
                                                name="phone"
                                                className={`form-control ${registrationFormik.touched.phone && registrationFormik.errors.phone && 'is-invalid'}`}
                                                placeholder={lanData?.phone_number}
                                                value={registrationFormik.values.phone}
                                                onChange={registrationFormik.handleChange}
                                                {...registrationFormik.getFieldProps('phone')}
                                            />
                                            {registrationFormik.touched.phone && registrationFormik.errors.phone
                                                ? (
                                                    <div className="invalid-feedback">
                                                        {registrationFormik.errors.phone}
                                                    </div>
                                                )
                                                : null }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-email">{lanData?.email}</label>
                                            <input
                                                id="register-email"
                                                type="email"
                                                name="email"
                                                className={`form-control ${registrationFormik.touched.email && registrationFormik.errors.email && 'is-invalid'}`}
                                                placeholder={lanData?.your_email_address}
                                                value={registrationFormik.values.email}
                                                onChange={registrationFormik.handleChange}
                                                {...registrationFormik.getFieldProps('email')}
                                            />
                                            {registrationFormik.touched.email && registrationFormik.errors.email
                                                ? (
                                                    <div className="invalid-feedback">
                                                        {registrationFormik.errors.email}
                                                    </div>
                                                )
                                                : null }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-password">{lanData?.password}</label>
                                            <input
                                                id="register-password"
                                                type="password"
                                                name="password"
                                                className={`form-control ${registrationFormik.touched.password && registrationFormik.errors.password && 'is-invalid'}`}
                                                placeholder={lanData?.password}
                                                value={registrationFormik.values.password}
                                                onChange={registrationFormik.handleChange}
                                                {...registrationFormik.getFieldProps('password')}
                                            />
                                            {registrationFormik.touched.password && registrationFormik.errors.password
                                                ? (
                                                    <div className="invalid-feedback">
                                                        {registrationFormik.errors.password}
                                                    </div>
                                                )
                                                : null }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-confirm">
                                                {lanData?.confirm_password}
                                            </label>
                                            <input
                                                id="register-confirm"
                                                type="password"
                                                name="repeatPassword"
                                                className={`form-control ${registrationFormik.touched.repeatPassword && registrationFormik.errors.repeatPassword && 'is-invalid'}`}
                                                placeholder={lanData?.confirm_password}
                                                value={registrationFormik.values.repeatPassword}
                                                onChange={registrationFormik.handleChange}
                                                {...registrationFormik.getFieldProps('repeatPassword')}
                                            />
                                            {registrationFormik.touched.repeatPassword && registrationFormik.errors.repeatPassword
                                                ? (
                                                    <div className="invalid-feedback">
                                                        {registrationFormik.errors.repeatPassword}
                                                    </div>
                                                )
                                                : null }
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                            {lanData?.register}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        auth: state,
    };
}
export default connect(mapStateToProps)(AccountPageLogin);

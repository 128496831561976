// react
import React, { Component } from 'react';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { FaXTwitter } from 'react-icons/fa6';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
// import Share from '../blocks/Share';

const withState = (WrappedComponent) => (props) => {
    const state = useSelector((state) => state);
    return <WrappedComponent {...props} state={state} />;
};
const loadData = (product) => {
    if (product.variations.length === 1) {
        // product.name
        // product.variations[0].sku
        // product.images[0]
        // console.log(product, product.images[0], product.variations[0].sku, product.name);
        // this.selectColor(0, product.name);
    }
};
class Product extends Component {
    constructor(props) {
        super(props);
        const { state } = this.props;
        const lanData = state.languageData;
        loadData(this.props?.product);
        this.state = {
            quantity: 1,
            selectedColor: null,
            reviews: `${lanData?.reviews}`,
            status: `${lanData?.product_status}`,
            productQuantity: `${lanData?.quantity}`,
        };
        if (this.props?.product.variations.length === 1) {
            this.state = {
                quantity: 1,
                selectedColor: {
                    id: this.props?.product.variations[0].id,
                    img: this.props?.product.images[0].img,
                },

            };
        }
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    selectColor = (index, name) => {
        this.setState({
            selectedColor: {
                id: this.props?.product.variations[index].id,
                name,
                img: this.props?.product.variations[index].img,
            },
        });
        this.state?.selectedColor;
    };

    toastMessage = (message) => {
        toast(message, {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
                color: 'red',
                fontSize: '20px',
                direction: this.props?.state?.locale === 'en' ? 'ltr' : 'rtl',
            },
        });
    };

    render() {
        const {
            product, layout, wishlistAddItem, compareAddItem, cartAddItem, productAltImage,
        } = this.props;
        const {
            quantity, reviews, status, productQuantity,
        } = this.state;
        let prices;
        console.log(this.state?.selectedColor);

        if (product.compareAtPrice) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price">
                        <Currency value={product.price} />
                    </span>
                    <span className="product__old-price">
                        <Currency value={product.compareAtPrice} />
                    </span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={product.price} />;
        }
        return (
            <div className={`product product--layout--${layout} mt-3`}>
                <div className="product__content single-product">
                    <ProductGallery layout={layout} images={product.images} productAltImage={productAltImage} />
                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>
                        <h1 className="product__name">{product.name}</h1>
                        <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating value={product.rating} />
                            </div>
                            <div className="product__rating-legend">
                                {`${product.reviews} `}
                                {reviews}
                            </div>
                        </div>
                        <div className="text-info mt-2 ml-2">{product.name}</div>
                        <ul className="product__meta">
                            <li className="product__meta-availability">
                                {status}
                                <span className={product.stock > 0 ? 'text-success' : 'text-danger'}>
                                    <FormattedMessage id={product.availability} />
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            <FormattedMessage id="avilability" />
                            <span className="text-success">{product.availability}</span>
                        </div>

                        <div className="product__prices">{prices}</div>

                        <form className="product__options">
                            <div className="form-group product__option">
                                {product?.colors?.length ? (
                                    <div className="product__option-label" style={{ fontSize: 18 }}>
                                        <React.Fragment>
                                            <FormattedMessage id="color" />
                                        </React.Fragment>
                                    </div>
                                ) : null}
                                <div className="input-radio-color">
                                    <div className="input-radio-color__list">
                                        {product?.colors?.length ? (
                                            product.colors.map((color, index) => (
                                                <React.Fragment key={index}>
                                                    <div
                                                        style={{ fontSize: 18, marginTop: '10px' }}
                                                        role="button"
                                                        tabIndex={-1}
                                                        onKeyDown={() => this.selectColor(index, color.name)}
                                                        onClick={() => this.selectColor(index, color.name)}
                                                    >
                                                        <div>
                                                            {product?.variations[index].img !== '' ? (
                                                                <img
                                                                    style={{ marginRight: '10px', width: '85%' }}
                                                                    src={product?.variations[index].img}
                                                                    width="60"
                                                                    height="90"
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            <h6 style={{ marginRight: '60px', marginTop: '10px' }}>
                                                                Sku:
                                                                {' '}
                                                            </h6>
                                                            <h6>{product?.variations[index].sku}</h6>
                                                            <button
                                                                className={`color__item-2 ${
                                                                    this.state?.selectedColor?.id
                                                                    === product.variations[index].id
                                                                        ? 'new-line-2'
                                                                        : 'new-line'
                                                                }`}
                                                                type="button"
                                                            >
                                                                {color.name}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <React.Fragment>
                                                <div>
                                                    <h6 style={{ marginRight: '60px', marginTop: '10px' }}>Sku: </h6>
                                                    <h6>{product?.variations[0].sku}</h6>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label">
                                    <React.Fragment>
                                        {/* <FormattedMessage id="quantity" /> */}
                                        {productQuantity}
                                    </React.Fragment>
                                </label>
                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        {product.quantity}
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            max={product.quantity}
                                            min={1}
                                            value={quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <AsyncAction
                                            action={() => {
                                                if (this.state?.selectedColor) {
                                                    cartAddItem(
                                                        {
                                                            ...product,
                                                            id: this.state?.selectedColor.id,
                                                            color: this.state?.selectedColor.name,
                                                            img: this.state?.selectedColor.img,
                                                        },
                                                        [],
                                                        quantity,
                                                        <FormattedMessage id="productHasBeenAddedToCartSuccessfully" />,
                                                    );
                                                } else {
                                                    this.toastMessage(<FormattedMessage id="noOptions.message" />);
                                                }
                                            }}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    disabled={!quantity}
                                                    className={classNames('btn btn-primary btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <FormattedMessage id="addToCart" />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => wishlistAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="product__footer">
                        {product?.tags?.length ? (
                            <div className="product__tags tags">
                                <div className="tags__list">
                                    {product.tags.map((tag) => (
                                        <Link to="/" onClick={(e) => e.preventDefault()}>
                                            {tag}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                        {/* <div className="product__share-links share-links">
                            <Share
                                media={product.images}
                                title={product.name}
                                description={product.descrition}
                                url={window.location.href}
                                hash={product.tags}
                            />
                        </div> */}
                        <div className="social-links  social-links--shape--circle">
                            <ul className="social-links__list">
                                <li className="social-links__item">
                                    <a
                                        className="social-links__link social-links_link--type--facebook"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.facebook.com/koolen.sa"
                                    >
                                        <i className="fab fa-facebook" />
                                    </a>
                                </li>
                                <li className="social-links__item">
                                    <a
                                        className="social-links__link social-links_link--type--facebook"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.instagram.com/koolenksa/"
                                    >
                                        <i className="fab fa-instagram" />
                                    </a>
                                </li>
                                <li className="social-links__item">
                                    <a
                                        className="social-links__link social-links_link--type--twitter"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://twitter.com/KoolenKSA"
                                    >
                                        <FaXTwitter />
                                    </a>
                                </li>
                                <li className="social-links__item">
                                    <a
                                        className="social-links__link social-links_link--type--twitter"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.snapchat.com/add/koolenksa"
                                    >
                                        <i className="fab fa-snapchat-ghost" />
                                    </a>
                                </li>
                                <li className="social-links__item">
                                    <a
                                        className="social-links__link social-links_link--type--twitter"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.tiktok.com/@koolen.ksa"
                                    >
                                        <i className="fab fa-tiktok" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default withState(connect(() => ({}), mapDispatchToProps)(Product));

// react
import { Component } from 'react';

// third-party
import PropTypes from 'prop-types';

class AsyncAction extends Component {
    canceled = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    componentWillUnmount() {
        this.canceled = true;
    }

    run = () => {
        const { action } = this.props;
        const { loading } = this.state;

        if (loading || !action) {
            return;
        }

        try {
            this.setState({ loading: true });
            action().then(() => {
                if (this.canceled) {
                    return;
                }
                this.setState({ loading: false });
            });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false });
        }
    };

    render() {
        const { render } = this.props;
        const { loading } = this.state;

        if (render) {
            return render({ run: this.run, loading });
        }

        return null;
    }
}

AsyncAction.propTypes = {
    action: PropTypes.func,
    render: PropTypes.func,
};

export default AsyncAction;

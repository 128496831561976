// react
import React, { Fragment, useEffect, useState } from 'react';

// third-party
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// application
// import { ArrowRoundedLeft6x9Svg } from '../../svg';
// import { url } from '../../services/utils';
import { getAllCategories } from '../../api/categories';
import { toastError } from '../toast/toastComponent';

function FilterCategory({ selectedCategory, changeSelectedCategory }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getAllCategories((success) => {
            if (success.success) {
                setCategories(success.data);
            } else {
                toastError(success);
            }
        }, (fail) => toastError(fail));
    }, []);

    function changeCategory(category) {
        changeSelectedCategory(category.slug);
    }

    const categoriesList = categories.map((category) => {
        const itemClasses = classNames('filter-categories__item', {
            // 'filter-categories__item--current': data.value === category.slug,
            // if in the same page make it as current
            'filter-categories__item--current': selectedCategory === category.slug,
        });

        return (
            <Fragment key={category.id}>
                <li className={itemClasses}>
                    {/* <Link to={`/shop/catalog/${category.slug}`} onClick={() => changeCategory(category)}>{category.name}</Link> */}
                    <Link to={`/collections/${category.slug}`} onClick={() => changeCategory(category)}>{category.name}</Link>
                </li>
            </Fragment>
        );
    });

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                {categoriesList}
            </ul>
        </div>
    );
}

export default FilterCategory;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import './ChangePassword.css';
import { resetPassword } from '../../api/auth';
import { toastSuccess, toastError } from '../toast/toastComponent';

const ChangePassword = () => {
    const state = useSelector((state) => state);
    const lanData = state.languageData;
    const intl = useIntl();
    const history = useHistory();
    const changePasswordFormik = useFormik({
        initialValues: {
            email: history.location.state.email,
            code: '',
            password: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object({
            code: Yup.number()
                .typeError(intl.formatMessage({ id: 'codeMessage.error' }))
                .required(intl.formatMessage({ id: 'codeMessage.validate' })),
            password: Yup.string()
                .min(6, lanData?.password_must_be_minimum_6_characters)
                .required(intl.formatMessage({ id: 'validation.password.required' })),
            repeatPassword: Yup.string()
                .required(lanData?.confirm_password)
                .oneOf([Yup.ref('password'), null], lanData?.password_and_confirm_password_should_match),
        }),
        onSubmit: (values) => {
            const { password, code } = values;
            resetPassword(
                {
                    email: history.location.state.email,
                    password,
                    code,
                },
                (success) => {
                    if (success.success) {
                        toastSuccess(success);
                        history.push('/account/login');
                    } else {
                        toastError(success);
                    }
                },
                (fail) => {
                    toastError(fail);
                },
            );
        },
    });

    return (
        <div className="change-password">
            <div className="container">
                <div className="row">
                    <div className="mx-auto col-sm-10 col-md-8 col-lg-6 col-12">
                        <div className="content my-5 my-lg-16 card">
                            <h3 className="lh-1 mb-2">{lanData?.password_reset}</h3>
                            <form onSubmit={changePasswordFormik.handleSubmit}>
                                <div className="mb-2">
                                    <div className="mb-1 fs-13 fw-500">{lanData?.email}</div>
                                    <div className="v-input v-input--hide-details theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                                        <div className="v-input__control">
                                            <div className="v-input__slot">
                                                <div className="v-text-field__slot">
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        placeholder={lanData?.your_email_address}
                                                        className={`form-control ${changePasswordFormik.touched.email && changePasswordFormik.errors.email && 'is-invalid'}`}
                                                        value={history.location.state.email}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="mb-1 fs-13 fw-500">{lanData?.code}</div>
                                    <div className="v-otp-input theme--light">
                                        <div className="v-input v-input--hide-details theme--light v-text-field v-text-field--is-booted v-text-field--outlined">
                                            <div className="v-input__control">
                                                <div className="v-input__slot">
                                                    <div className="v-text-field__slot">
                                                        <input
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            placeholder={lanData?.code}
                                                            className={`form-control ${changePasswordFormik.touched.code && changePasswordFormik.errors.code && 'is-invalid'}`}
                                                            value={changePasswordFormik.values.code}
                                                            onChange={changePasswordFormik.handleChange}
                                                            {...changePasswordFormik.getFieldProps('code')}
                                                        />
                                                        {
                                                            changePasswordFormik.touched.code && changePasswordFormik.errors.code
                                                                ? (
                                                                    <div className="invalid-feedback">
                                                                        {changePasswordFormik.errors.code}
                                                                    </div>
                                                                )
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="mb-1 fs-13 fw-500">{lanData?.password}</div>
                                    <div className="v-input v-input--hide-details theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                                        <div className="v-input__control">
                                            <div className="v-input__slot">
                                                <div className="v-text-field__slot">
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        className={`form-control ${changePasswordFormik.touched.password && changePasswordFormik.errors.password && 'is-invalid'}`}
                                                        placeholder={lanData?.password}
                                                        value={changePasswordFormik.values.password}
                                                        onChange={changePasswordFormik.handleChange}
                                                        {...changePasswordFormik.getFieldProps('password')}
                                                    />
                                                    {
                                                        changePasswordFormik.touched.password && changePasswordFormik.errors.password
                                                            ? (
                                                                <div className="invalid-feedback">
                                                                    {changePasswordFormik.errors.password}
                                                                </div>
                                                            )
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="mb-1 fs-13 fw-500">{lanData?.confirm_password}</div>
                                    <div className="v-input v-input--hide-details theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                                        <div className="v-input__control">
                                            <div className="v-input__slot">
                                                <div className="v-text-field__slot">
                                                    <input
                                                        id="repeatPassword"
                                                        type="password"
                                                        name="repeatPassword"
                                                        className={`form-control ${changePasswordFormik.touched.repeatPassword && changePasswordFormik.errors.repeatPassword && 'is-invalid'}`}
                                                        placeholder={lanData?.confirm_password}
                                                        value={changePasswordFormik.values.repeatPassword}
                                                        onChange={changePasswordFormik.handleChange}
                                                        {...changePasswordFormik.getFieldProps('repeatPassword')}
                                                    />
                                                    {
                                                        changePasswordFormik.touched.repeatPassword && changePasswordFormik.errors.repeatPassword
                                                            ? (
                                                                <div className="invalid-feedback">
                                                                    {changePasswordFormik.errors.repeatPassword}
                                                                </div>
                                                            )
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn" type="submit">
                                    {lanData?.reset_password}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;

import GET_LANGUAGE_DATA from './lan.types';

const initState = {};

export default function lanReducer(state = initState, action) {
    switch (action.type) {
    case GET_LANGUAGE_DATA:
        return action.payload;
    default:
        return state;
    }
}

import React from 'react';
// import React, { useEffect, useRef } from 'react'; // Add useEffect and useRef

import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import './AccountVerify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { toastSuccess, toastError } from '../toast/toastComponent';
import { resendCode, verifyCode } from '../../api/auth';
import { LOGIN } from '../../store/auth/auth.types';
import { getToken } from '../../api/network';

const AccountVerify = (props) => {
    const state = useSelector((state) => state);
    const { dispatch } = props;
    const lanData = state?.languageData;
    const intl = useIntl();
    const history = useHistory();
    // console.log(props);
    // console.log(props?.theEmail);

    const formik = useFormik({
        initialValues: {
            // email: state?.auth?.user?.email || '',
            email: history?.location?.state?.email || '',
            code: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(lanData?.please_enter_a_valid_email_address)
                .required(lanData?.enter_your_email_address),
            code: Yup.number()
                .typeError(intl.formatMessage({ id: 'codeMessage.error' }))
                .required(intl.formatMessage({ id: 'codeMessage.validate' })),
        }),
    });
    const resendCodeFn = (e) => {
        e.preventDefault();
        const payload = {
            email: formik.values.email,
        };
        resendCode(
            payload,
            (success) => {
                if (success.success) {
                    toastSuccess(success);
                } else {
                    toastError(success);
                }
            },
            (fail) => toastError(fail),
        );
    };

    const verifyCodeFn = (e) => {
        e.preventDefault();
        const payload = {
            email: formik.values.email,
            code: formik.values.code,
        };
        verifyCode(
            payload,
            (success) => {
                if (success.success) {
                    toastSuccess(success);
                    const { access_token: token, user } = success;
                    getToken(token);
                    dispatch({ type: LOGIN, payload: { token, user } });
                    history.push('/');
                } else {
                    toastError(success);
                }
            },
            (fail) => toastError(fail),
        );
    };

    return (
        <div className="account-verify">
            <div className="container">
                <div className="row">
                    <div className="mx-auto col-sm-10 col-md-8 col-lg-6 col-12">
                        <div className="content my-5 my-lg-16 border overflow-hidden shadow-light">
                            <div className="info--text mb-3 title-1">
                                {lanData?.a_verification_code_has_been_sent_to_your_email}
                            </div>
                            <h3 className="text-uppercase lh-1 mb-4">
                                <div className="primary--text fw-900">{lanData?.verify}</div>
                                <div className="d-block fw-900 grey--text text--darken-3">{lanData?.account}</div>
                            </h3>
                            <div className="title-2 mb-3">{lanData?.enter_your_email_address_verification_code}</div>
                            <form>
                                <div className="mb-4">
                                    <div className="mb-1 fs-13 fw-500">{lanData?.email}</div>
                                    <div className="v-input v-input--hide-details theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                                        <div className="v-input__control">
                                            <div className="v-input__slot">
                                                <div className="v-text-field__slot">
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        placeholder={lanData?.your_email_address}
                                                        className={`form-control ${
                                                            formik.touched.email && formik.errors.email && 'is-invalid'
                                                        }`}
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        {...formik.getFieldProps('email')}
                                                    />
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="mb-1 fs-13 fw-500">{lanData?.code}</div>
                                    <div className="v-otp-input theme--light">
                                        <div className="v-input v-input--hide-details theme--light v-text-field v-text-field--is-booted v-text-field--outlined">
                                            <div className="v-input__control">
                                                <div className="v-input__slot">
                                                    <div className="v-text-field__slot">
                                                        <input
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            placeholder={lanData?.code}
                                                            className={`form-control ${
                                                                formik.touched.code
                                                                && formik.errors.code
                                                                && 'is-invalid'
                                                            }`}
                                                            value={formik.values.code}
                                                            onChange={formik.handleChange}
                                                            {...formik.getFieldProps('code')}
                                                        />
                                                        {formik.touched.code && formik.errors.code ? (
                                                            <div className="invalid-feedback">{formik.errors.code}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="buttons-container d-flex justify-content-between">
                                    <button className="btn verify" type="submit" onClick={verifyCodeFn}>
                                        {lanData?.verify}
                                    </button>
                                    <button className="btn resend-code" type="submit" onClick={resendCodeFn}>
                                        {lanData?.resend_code}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        auth: state,
    };
}
export default connect(mapStateToProps)(AccountVerify);

// react
import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
import { useDispatch, connect } from 'react-redux';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import BlogPageCategory from './blog/BlogPageCategory';
import BlogPagePost from './blog/BlogPagePost';
import PageCart from './shop/ShopPageCart';
import PageCheckout from './shop/ShopPageCheckout';
import PageCompare from './shop/ShopPageCompare';
import PageWishlist from './shop/ShopPageWishlist';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageProduct from './shop/ShopPageProduct';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageContactUsAlt from './site/SitePageContactUsAlt';
import SitePageFaq from './site/SitePageFaq';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTypography from './site/SitePageTypography';
import CategoriesPage from './categories/Categories';
import AirConditions from './site/SitePageAirConditions';
import ShopPageOrderFailure from './shop/ShopPageOrderFailure';
import AccountVerify from './account/AccountVerify';
import FooterLinkPage from './footer/FooterLinkPage';
// import CheckoutEditAddress from './account/CheckoutEditAddress';

// data stubs
import theme from '../data/theme';

// apis
import { getToken } from '../api/network';
import ForgetPassword from './account/ForgetPassword';
import ChangePassword from './account/ChangePassword';
import { fetchata } from '../store/language';

const categoryLayouts = [
    ['/shop/category-grid-3-columns-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'start' }],
    ['/shop/category-grid-4-columns-full', { columns: 4, viewMode: 'grid' }],
    ['/shop/category-grid-5-columns-full', { columns: 5, viewMode: 'grid' }],
    ['/shop/category-list', { columns: 3, viewMode: 'list', sidebarPosition: 'start' }],
    ['/shop/category-right-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'end' }],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageCategory {...props} {...options} categorySlug="power-tools" />}
    />
));

const productLayouts = [
    ['/shop/product-standard', { layout: 'standard' }],
    ['/shop/product-columnar', { layout: 'columnar' }],
    ['/shop/product-sidebar', { layout: 'sidebar' }],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageProduct {...props} {...options} productSlug="brandix-screwdriver-screw1500acc" />}
    />
));

function Layout(props) {
    const {
        match, headerLayout, homeComponent, auth,
    } = props;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchata());
    }, []);

    useEffect(() => {
        if (auth?.token) {
            getToken(auth?.token);
        }
    }, [auth]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>
            <Quickview />
            <MobileMenu />
            <ToastContainer limit={1} />

            <div className="site">
                <header className="mobile-header-parent site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        {/* Home */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        {/* Shop */}
                        <Redirect exact from="/shop" to="/shop/catalog" />
                        <Route
                            exact
                            path="/shop/catalog"
                            render={(props) => (
                                <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            // path="/shop/catalog/:categorySlug"
                            path="/collections/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            // path="/shop/catalog/:categorySlug"
                            path="/collections/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                />
                            )}
                        />
                        {/* Following category layouts only for demonstration. */}
                        {categoryLayouts}

                        <Route
                            exact
                            // path="/shop/products/:productSlug"
                            path="/products/:productSlug"
                            render={(props) => (
                                <ShopPageProduct
                                    {...props}
                                    layout="standard"
                                    productSlug={props.match.params.productSlug}
                                />
                            )}
                        />
                        {/* Following product layouts only for demonstration. */}
                        {productLayouts}

                        <Route exact path="/shop/cart" component={PageCart} />
                        <Route
                            exact
                            path="/shop/checkout"
                            render={() => {
                                if (auth?.token) return <PageCheckout />;
                                return <Redirect to="/account/login" />;
                            }}
                        />
                        <Route exact path="/account/verify" component={AccountVerify} />
                        <Route exact path="/account/forget-password" component={ForgetPassword} />
                        <Route exact path="/account/change-password" component={ChangePassword} />
                        <Route exact path="/shop/checkout/success" component={ShopPageOrderSuccess} />
                        <Route exact path="/shop/checkout/failure" component={ShopPageOrderFailure} />
                        <Route exact path="/shop/wishlist" component={PageWishlist} />
                        <Route exact path="/shop/compare" component={PageCompare} />
                        <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />

                        {/*
                        // Blog
                        */}
                        <Redirect exact from="/blog" to="/blog/category-classic" />
                        <Route
                            exact
                            path="/blog/category-classic"
                            render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/category-grid"
                            render={(props) => <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/category-list"
                            render={(props) => <BlogPageCategory {...props} layout="list" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/category-left-sidebar"
                            render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />}
                        />
                        <Route
                            exact
                            path="/blog/post-classic"
                            render={(props) => <BlogPagePost {...props} layout="classic" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/post-full"
                            render={(props) => <BlogPagePost {...props} layout="full" />}
                        />

                        {/*
                        // Account

                        */}
                        <Route
                            path="/account/forget-password"
                            render={() => {
                                if (auth.token) return <ForgetPassword />;
                                return <Redirect to="/account/login" />;
                            }}
                        />
                        <Route
                            path="/account/change-password"
                            render={() => {
                                if (auth.token) return <ChangePassword />;
                                return <Redirect to="/account/login" />;
                            }}
                        />
                        <Route
                            exact
                            path="/account/login"
                            render={() => {
                                if (!auth?.token) return <AccountPageLogin />;
                                return <Redirect to="/" />;
                            }}
                        />
                        <Route path="/account" component={AccountLayout} />

                        {/*
                        // Site
                        */}
                        <Redirect exact from="/pages" to="/pages/about-us" />
                        <Route exact path="/pages/contact-us" component={SitePageContactUs} />
                        <Route exact path="/pages/:linkPage" component={FooterLinkPage} />
                        <Route exact path="/pages/contact-us-alt" component={SitePageContactUsAlt} />
                        <Route exact path="/pages/not-found" component={SitePageNotFound} />
                        <Route exact path="/pages/faq" component={SitePageFaq} />
                        <Route exact path="/pages/typography" component={SitePageTypography} />
                        {/* <Route exact path="/categories" component={CategoriesPage} /> */}
                        <Route exact path="/collections" component={CategoriesPage} />
                        {/* <Route exact path="/split-air-conditions" component={AirConditions} /> */}
                        {/* <Route exact path="/collections/split-air-conditions" component={AirConditions} /> */}
                        {/* <Route exact path="/collections/مكيفات-سبليت" component={AirConditions} /> */}
                        <Route exact path="/مكيفات-سبليت" component={AirConditions} />
                        {/* <Route exact path="/add/address" component={CheckoutEditAddress} /> */}

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(Layout);
